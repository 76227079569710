import { createContext } from 'react'

import { PlayContext } from '~/constants'

export const GameContext = createContext<{
  slug: GameSlug | ''
  playContext: PlayContext
  onSlugChange: OnSlugChange
  areaSlug: AreaSlug | ''
  workoutSlotKey: keyof WorkoutSlots
  workoutDate: string
}>({
  slug: '',
  playContext: PlayContext.FreePlay,
  onSlugChange: () => {
    return
  },
  areaSlug: '',
  workoutSlotKey: 'slot1',
  workoutDate: '',
})
