import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  LocalDate: string;
};

export type AgeCohortComparison = {
  __typename?: 'AgeCohortComparison';
  ageCohortSlug: Scalars['String'];
  bestOverallPercentile?: Maybe<Scalars['Float']>;
  overallPercentile?: Maybe<Scalars['Float']>;
  percentileByArea: Array<AreaPercentile>;
};

export type AreaLpi = {
  __typename?: 'AreaLpi';
  areaSlug: Scalars['String'];
  lpi?: Maybe<Scalars['Int']>;
};

export type AreaPercentile = {
  __typename?: 'AreaPercentile';
  areaSlug: Scalars['String'];
  percentile: Scalars['Float'];
};

export type CurrentUser = {
  __typename?: 'CurrentUser';
  id?: Maybe<Scalars['Int']>;
  settings?: Maybe<UiSettings>;
};

export enum DayOfWeek {
  Friday = 'friday',
  Monday = 'monday',
  Saturday = 'saturday',
  Sunday = 'sunday',
  Thursday = 'thursday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday'
}

export type DemographicInput = {
  /** User's selected education */
  education?: InputMaybe<Scalars['String']>;
  /** User's selected gender */
  gender?: InputMaybe<Scalars['String']>;
  /** User's selected occupation */
  occupation?: InputMaybe<Scalars['String']>;
  /** User's selected referral source */
  referralSource?: InputMaybe<Scalars['String']>;
};

export type FitTest = {
  __typename?: 'FitTest';
  percentiles?: Maybe<Array<Maybe<GamePercentile>>>;
};

/** Represents a game. */
export type Game = {
  __typename?: 'Game';
  /** Area to which this game belongs. */
  areaSlug: Scalars['String'];
  /** Slug representing game. */
  slug: Scalars['String'];
};

export enum GameEngine {
  Cocos_2D = 'COCOS_2D',
  Cocos_3 = 'COCOS_3',
  Unity_2019 = 'UNITY_2019'
}

/** Represents the output from the client when a user finishes playing a game */
export type GameOutput = {
  __typename?: 'GameOutput';
  /** Time of row creation in epoch milliseconds (UTC). */
  createdAt: Scalars['Float'];
  /** Game Engine that generated the game output */
  engine: GameEngine;
  /** When game play was finished in epoch milliseconds (UTC). */
  finishedAt: Scalars['Float'];
  /** Raw JSON string of game engine output */
  gameOutput: Scalars['String'];
  /** Provided game slug string from client */
  gameSlug: Scalars['String'];
  /** Id generated as MD5 hash from raw game ouput string */
  id: Scalars['ID'];
  /** Platform of Client sending request */
  platform: Platform;
  /** Time of mutation request received in epoch milliseconds (UTC). */
  receivedAt: Scalars['Float'];
  /** Id of user completing the game */
  userId: Scalars['Int'];
  /** Game variant of played game. Defaults to 'Regular' */
  variant: GameVariant;
};

export type GamePercentile = {
  __typename?: 'GamePercentile';
  gamePlay: GamePlay;
  gameSlug: Scalars['String'];
  percentile: Scalars['Float'];
};

/** Represents an individual play of a game */
export type GamePlay = {
  __typename?: 'GamePlay';
  /** When game play was finished in epoch milliseconds (UTC). */
  finishedAt: Scalars['Float'];
  game: Game;
  /** Server generated ID. */
  id: Scalars['Int'];
  /** Level that user achieved for this Gameplay. */
  levelNext: Scalars['Int'];
  /**
   * Highest level that the Game allowed the user to start at for this Gameplay.
   * Value is 0 if game does not have level syncing.
   */
  levelPrevious: Scalars['Int'];
  /**
   * Level that user selected to start at for this Gameplay.
   * Value is 0 if game does not have level syncing.
   */
  levelSelected: Scalars['Int'];
  /** The LPI for this particular GamePlay (based on the gameSlug, score, and platform) */
  lpi: Scalars['Int'];
  /** FK - ID of the associated GameOutput */
  outputId: Scalars['String'];
  /** Platform on which game was played */
  platform: Platform;
  /** Score resulting from this `GamePlay`. */
  score: Scalars['Int'];
  /** Current score's position relative to best score. */
  scorePosition: Scalars['Int'];
  /** Stat resulting from this `GamePlay`. Could be null if the `Game` does not have a stat. */
  stat?: Maybe<Scalars['Int']>;
  /** Specific variant of the game played */
  variant: GameVariant;
};

/** Generated upon completion of a game play */
export type GamePlayInput = {
  /** When game play was finished in epoch milliseconds (UTC). */
  finishedAt: Scalars['Float'];
  /** Stringified output from game engine */
  gameOutput: Scalars['String'];
  /** Game this play relates to. */
  gameSlug: Scalars['String'];
  /** Optional Game variant, for example, a Fit Test (missing value indicates a regular game play) */
  variant?: InputMaybe<GameVariant>;
  /** Optional date indicating when the workout was begun */
  workoutDate?: InputMaybe<Scalars['String']>;
  /** Optional Unique identifier for the workout associated with this game play if this gameplay was associated with a workout */
  workoutId?: InputMaybe<Scalars['ID']>;
  /** Workout slot this play related to. */
  workoutSlotKey: Scalars['String'];
};

/** Represents the user's progress with respect to a particular game */
export type GameProgress = {
  __typename?: 'GameProgress';
  /**
   * Returns the `GamePlay` representing a `User`'s best stat for given `Platform` (via header) and `GameEngine`.
   * A `null` value signifies the `User` has never finished the `Game`.
   */
  bestStat?: Maybe<GamePlay>;
  /** `Game` data for this `GameProgress`. */
  game: Game;
  /**
   * The most recent play of this game for the current `Platform` and `GameEngine`.
   * A `null` value signifies the `User` has never played this `Game`.
   */
  lastGamePlay?: Maybe<GamePlay>;
  /**
   * Number of plays by a `User` for this [game](#game) to reach the next rank.
   * Applies across all `Platform`s and `GameEngine`s (i.e. canonical).
   */
  nextRankAt: Scalars['Int'];
  /**
   * `GameEngine` **and** `Platform` formatted stringified JSON (i.e. keys and values are specific to the `Platform` (via header) and `GameEngine`).
   * Can contain game-specific state (i.e. tower height or mute).
   * Includes canonical level data (`levelNext`).
   * Returns `null` if the `User` has never played this `Game` on any `Platform`.
   * Note that a non-null value is possible for a `Platform` a `User` has never played on due to canonical data (`level_next`). This can happen when a `User` plays on another platform.
   * # Example `UNITY_2021` Format for `contextual`
   * ```json
   * {
   *     "level_next": 1,
   *     "LumosLabs.Contextual.StoryView8": 1,
   *     "LumosLabs.Contextual.StoryView11": 1,
   *     "LumosLabs.Contextual.StoryView98": 1
   * }
   * ```
   * # Example `COCOS_2D` Format for `contextual`
   * ```json
   * {
   *     "user_level": 1,
   *     "savedUserData": {
   *         "recentShelves": [14],
   *         "previousViews": {
   *             "35": 1
   *         },
   *         "readingComp": 40
   *     }
   * }
   * # Example `COCOS_3` Format for `contextual`
   * ```json
   * {
   *     "user_level": 1,
   *     "savedUserData": {
   *         "recentShelves": [14],
   *         "previousViews": {
   *             "35": 1
   *         },
   *         "readingComp": 40
   *     }
   * }
   * ```
   */
  playerState?: Maybe<Scalars['JSONObject']>;
  /**
   * The number of game plays a user has completed after achieving their current rank.
   * For example the rank newcomer is achieved after completing 3 gameplays and the next rank is achieved after 8 game plays. So if a user has 5 gameplays, plays at rank is calculated as `5 - 3`.
   */
  playsAtRank: Scalars['Int'];
  /**
   * String representation of a `User`'s current rank for this [game](#game).
   * Applies across all `Platform`s and `GameEngine`s (i.e. canonical).
   */
  rank: Scalars['String'];
  /**
   * `GamePlay`s ordered by score (highest to lowest) for given `Platform` (via header) and `GameEngine`.
   * An empty array signifies the `User` has never finished the `Game`. Results are limited to 100.
   */
  topScores: Array<Maybe<GamePlay>>;
  /**
   * `GamePlay`s ordered by stat (highest to lowest) for given `Platform` (via header) and `GameEngine`.
   * An empty array signifies the `User` has never finished the `Game`. Results are limited to 2.
   */
  topStats: Array<Maybe<GamePlay>>;
  /**
   * Total number of plays by a `User` for this [game](#game) across all `Platform`s and `GameEngine`s (i.e. canonical).
   * Primarily used for ranking purposes.
   */
  totalPlays: Scalars['Int'];
};


/** Represents the user's progress with respect to a particular game */
export type GameProgressTopScoresArgs = {
  count?: InputMaybe<Scalars['Int']>;
};

export type GameUnlock = {
  __typename?: 'GameUnlock';
  /** LocalDate game unlock ends (YYYY-MM-DD) */
  endDate: Scalars['LocalDate'];
  gameSlug: Scalars['String'];
  /** LocalDate game unlock starts (YYYY-MM-DD) */
  startDate: Scalars['LocalDate'];
};

export type GameUnlockInput = {
  /** LocalDate game unlock ends (YYYY-MM-DD) */
  endDate: Scalars['LocalDate'];
  /** Game this play relates to. */
  gameSlug: Scalars['String'];
  /** LocalDate game unlock starts (YYYY-MM-DD) */
  startDate: Scalars['LocalDate'];
};

export enum GameVariant {
  FitTest = 'FitTest',
  Regular = 'Regular'
}

export type GetOrCreateUserInput = {
  birthdate: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  migratedFromFlagship?: InputMaybe<Scalars['Boolean']>;
  userId: Scalars['Int'];
};

export type LevelNextForGame = {
  __typename?: 'LevelNextForGame';
  gameSlug?: Maybe<Scalars['String']>;
  levelNext?: Maybe<Scalars['Int']>;
};

export type LpiSummary = {
  __typename?: 'LpiSummary';
  ageCohortComparison: AgeCohortComparison;
  bestOverallLpi?: Maybe<Scalars['Int']>;
  lpisByArea: Array<AreaLpi>;
  overallLpi?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['Float'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Adds game play for a User with raw Cocos2d game output. Returns GUID for the `GamePlay` as acknowledgement. */
  addCocos2dGameOutput: GameOutput;
  /** Adds game play for a User with raw Cocos 3 game output. Returns GUID for the `GamePlay` as acknowledgement. */
  addCocos3GameOutput: GameOutput;
  /** Adds a game unlock */
  addGameUnlockEntry: GameUnlock;
  /** Adds an admin note associated with a user account, returning ID of the created note */
  addNote: Scalars['ID'];
  /** Adds game play for a User with raw Unity game output. Returns GUID for the `GamePlay` as acknowledgement. */
  addUnityGameOutput: GameOutput;
  /** Creates a record of a workout in the database of a workout created on mobile while the client is offline */
  createOfflineWorkout: Scalars['ID'];
  /** Add a user if they don't exist */
  getOrCreateUser: User;
  /** Repair a user's streak by joining the last two streaks */
  repairLastStreak: Streak;
  /** Updates a user's birthdate */
  updateBirthdate: User;
  /** Adds user input from the demographic onboarding survey */
  updateDemographics: User;
  /** Updates a user's email in their profile */
  updateEmailInUserProfile: User;
  updateGameLevel: LevelNextForGame;
  /** Set user locale */
  updateLocale: User;
  /** Updates a user's name in their profile */
  updateName: User;
  /** Set user training reminder schedule */
  updateReminderSchedule: ReminderSchedule;
  /** Adds user input from the demographic onboarding survey */
  updateUserDemographics: User;
  /** Updates the list of games in a Workout. Returns the new workout games list */
  updateWorkoutGame: Workout;
};


export type MutationAddCocos2dGameOutputArgs = {
  input: GamePlayInput;
};


export type MutationAddCocos3GameOutputArgs = {
  input: GamePlayInput;
};


export type MutationAddGameUnlockEntryArgs = {
  input: GameUnlockInput;
};


export type MutationAddNoteArgs = {
  input: UserNoteInput;
};


export type MutationAddUnityGameOutputArgs = {
  input: GamePlayInput;
};


export type MutationCreateOfflineWorkoutArgs = {
  input: OfflineWorkoutInput;
};


export type MutationGetOrCreateUserArgs = {
  input: GetOrCreateUserInput;
};


export type MutationRepairLastStreakArgs = {
  userId: Scalars['Int'];
};


export type MutationUpdateBirthdateArgs = {
  input: UpdateBirthdateInput;
};


export type MutationUpdateDemographicsArgs = {
  input: DemographicInput;
};


export type MutationUpdateEmailInUserProfileArgs = {
  email: Scalars['String'];
  userIdToUpdate?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateGameLevelArgs = {
  gameSlug: Scalars['String'];
  levelNext: Scalars['Int'];
  userId: Scalars['Int'];
};


export type MutationUpdateLocaleArgs = {
  input: UpdateLocaleInput;
};


export type MutationUpdateNameArgs = {
  input: UpdateNameInput;
};


export type MutationUpdateReminderScheduleArgs = {
  input: UpdateReminderScheduleInput;
};


export type MutationUpdateUserDemographicsArgs = {
  input: DemographicInput;
  userId: Scalars['Int'];
};


export type MutationUpdateWorkoutGameArgs = {
  newGameSlug: Scalars['String'];
  workoutId: Scalars['ID'];
  workoutSlot: Scalars['Int'];
};

export type OfflineWorkoutInput = {
  /** Timestamp for when the offline workout was created on the client, in epoch milliseconds (UTC). */
  createdAt: Scalars['Float'];
  /** Array of game slugs in the workout */
  gameSlugs: Array<Scalars['String']>;
  /** Unique identifier for the workout created while offline */
  workoutId: Scalars['ID'];
};

export enum Platform {
  Mobile = 'mobile',
  Web = 'web'
}

export type Query = {
  __typename?: 'Query';
  GetCurrentUser?: Maybe<CurrentUser>;
  /** Fetch all games */
  games: Array<Game>;
  /** Fetch unlocked games */
  gamesUnlocked: Array<GameUnlock>;
  /** Fetch info about a user, optionally pass in the userId for Admin requests */
  me: User;
  /** Fetch user profile admin notes */
  notes: Array<UserNotes>;
  /** Fetch user training reminder schedule */
  reminderSchedule: ReminderSchedule;
  todaysWorkout: Workout;
  /** Fetch user IDs by email match */
  userIdsByMatchingEmail?: Maybe<Array<Maybe<User>>>;
  /** Education options */
  validEducation: Array<Scalars['String']>;
  /** Gender options */
  validGenders: Array<Scalars['String']>;
  /** Occupation options */
  validOccupations: Array<Scalars['String']>;
  /** Referral Source options */
  validReferralSources: Array<Scalars['String']>;
  /** dates with session activity */
  workoutDates: Array<Scalars['LocalDate']>;
};


export type QueryGamesUnlockedArgs = {
  availableOnDate?: InputMaybe<Scalars['LocalDate']>;
};


export type QueryMeArgs = {
  userIdOverride?: InputMaybe<Scalars['Int']>;
};


export type QueryNotesArgs = {
  userId: Scalars['Int'];
};


export type QueryReminderScheduleArgs = {
  userId: Scalars['Int'];
};


export type QueryTodaysWorkoutArgs = {
  currentDate?: InputMaybe<Scalars['LocalDate']>;
  knownGameSlugs: Array<Scalars['String']>;
};


export type QueryUserIdsByMatchingEmailArgs = {
  email: Scalars['String'];
};

export type ReminderSchedule = {
  __typename?: 'ReminderSchedule';
  days: Array<DayOfWeek>;
  timeSlot: ReminderTimeSlot;
};

export enum ReminderTimeSlot {
  Afternoon = 'afternoon',
  Evening = 'evening',
  Morning = 'morning'
}

export type Streak = {
  __typename?: 'Streak';
  /** LocalDate streak ended (YYYY-MM-DD) */
  endDate: Scalars['LocalDate'];
  /** Length of streak in days. */
  length: Scalars['Int'];
  /** LocalDate streak began (YYYY-MM-DD) */
  startDate: Scalars['LocalDate'];
};

export type StreakHistory = {
  __typename?: 'StreakHistory';
  /** All time longest streak for user. */
  bestStreak?: Maybe<Streak>;
  /** Collection of streaks. Defaults to all-time history. Optional date range may be provided. */
  streaks: Array<Streak>;
};


export type StreakHistoryStreaksArgs = {
  endDate?: InputMaybe<Scalars['LocalDate']>;
  startDate?: InputMaybe<Scalars['LocalDate']>;
};

export type UiSettings = {
  __typename?: 'UISettings';
  game_sound_on?: Maybe<Scalars['Boolean']>;
  showSideNav?: Maybe<Scalars['Boolean']>;
};

export type UpdateBirthdateInput = {
  birthdate: Scalars['LocalDate'];
  userIdToUpdate?: InputMaybe<Scalars['Int']>;
};

export type UpdateLocaleInput = {
  locale: Scalars['String'];
  userIdToUpdate?: InputMaybe<Scalars['Int']>;
};

export type UpdateNameInput = {
  name: Scalars['String'];
  userIdToUpdate?: InputMaybe<Scalars['Int']>;
};

export type UpdateReminderScheduleInput = {
  days: Array<DayOfWeek>;
  timeSlot: ReminderTimeSlot;
  userIdToUpdate?: InputMaybe<Scalars['Int']>;
};

export type User = {
  __typename?: 'User';
  ageCohort: Scalars['String'];
  ageCohortSlug: Scalars['String'];
  birthdate: Scalars['LocalDate'];
  createdAt: Scalars['Float'];
  education?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  /** Get the Fit Test results for a user */
  fitTest: FitTest;
  /**
   * The `GameProgress` for given `Game`s for this `User`.
   * Further filtered by given parameters (`gameSlug`, `engine` and `version`).
   * Platform is inferred by the `platform` header.
   */
  gamesProgress: Array<GameProgress>;
  gender?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /**
   * Get the levelNext for all games for the user, which is saved after a gameplay in user_game_data_cross_platform
   * and will be the level they start the next game with
   */
  levelNextForAllGames: Array<Maybe<LevelNextForGame>>;
  locale?: Maybe<Scalars['String']>;
  lpiSummary: LpiSummary;
  migratedAt?: Maybe<Scalars['Float']>;
  occupation?: Maybe<Scalars['String']>;
  referralSource?: Maybe<Scalars['String']>;
  streakHistory: StreakHistory;
  updatedAt: Scalars['Float'];
};


export type UserGamesProgressArgs = {
  engine?: InputMaybe<GameEngine>;
  slugs: Array<Scalars['String']>;
};


export type UserStreakHistoryArgs = {
  endDate?: InputMaybe<Scalars['LocalDate']>;
  startDate?: InputMaybe<Scalars['LocalDate']>;
};

export type UserNoteInput = {
  /** Text of the note, provided by admin */
  noteText: Scalars['String'];
  /** User Id */
  userId: Scalars['Int'];
};

export type UserNotes = {
  __typename?: 'UserNotes';
  adminAuthor: Scalars['String'];
  createdAt: Scalars['Float'];
  noteText: Scalars['String'];
  noteType: Scalars['String'];
};

export type Workout = {
  __typename?: 'Workout';
  /** When this `Workout` was last changed (inclusive of `workoutGames`) */
  createdAt: Scalars['Float'];
  id: Scalars['ID'];
  /** Is user's full program complete */
  programComplete?: Maybe<Scalars['Boolean']>;
  slots: WorkoutSlots;
  /** Ordered list of recommended `Game`s for this `Workout` */
  workoutGames: Array<WorkoutGame>;
};

export type WorkoutGame = {
  __typename?: 'WorkoutGame';
  game: Game;
  /**
   * Last time `Game` was played as part of parent `Workout`.
   * `null` indicates the `Game` was never played for parent `Workout`.
   * Note that this `Game` could have been played independently of parent `Workout` (i.e. Games tab).
   */
  lastGamePlay?: Maybe<GamePlay>;
};

export type WorkoutSlots = {
  __typename?: 'WorkoutSlots';
  slot1: WorkoutGame;
  slot2: WorkoutGame;
  slot3: WorkoutGame;
  slot4: WorkoutGame;
  slot5: WorkoutGame;
  slot6: WorkoutGame;
  slot7: WorkoutGame;
  slot8: WorkoutGame;
  slot9: WorkoutGame;
  slot10: WorkoutGame;
};

export type AddCocos3GameOutputMutationVariables = Exact<{
  input: GamePlayInput;
}>;


export type AddCocos3GameOutputMutation = { __typename?: 'Mutation', addCocos3GameOutput: { __typename?: 'GameOutput', id: string, finishedAt: number, receivedAt: number, createdAt: number, gameOutput: string, gameSlug: string, platform: Platform, engine: GameEngine, variant: GameVariant, userId: number } };

export type GetAccountStateQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAccountStateQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number } };

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = { __typename?: 'Query', GetCurrentUser?: { __typename?: 'CurrentUser', id?: number | null, settings?: { __typename?: 'UISettings', showSideNav?: boolean | null, game_sound_on?: boolean | null } | null } | null };

export type GetDemographicInputQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDemographicInputQuery = { __typename?: 'Query', validEducation: Array<string>, validGenders: Array<string>, validOccupations: Array<string>, validReferralSources: Array<string> };

export type GetFitTestProgressQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFitTestProgressQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, ageCohort: string, migratedAt?: number | null, fitTest: { __typename?: 'FitTest', percentiles?: Array<{ __typename?: 'GamePercentile', gameSlug: string, percentile: number, gamePlay: { __typename?: 'GamePlay', outputId: string } } | null> | null } } };

export type GameProgressFragment = { __typename?: 'GameProgress', totalPlays: number, rank: string, playsAtRank: number, nextRankAt: number, playerState?: any | null, game: { __typename?: 'Game', slug: string, areaSlug: string }, topScores: Array<{ __typename?: 'GamePlay', score: number, stat?: number | null, finishedAt: number } | null>, bestStat?: { __typename?: 'GamePlay', stat?: number | null } | null, topStats: Array<{ __typename?: 'GamePlay', score: number, stat?: number | null, finishedAt: number } | null>, lastGamePlay?: { __typename?: 'GamePlay', outputId: string, finishedAt: number, score: number, stat?: number | null } | null };

export type TopScoreFragment = { __typename?: 'GamePlay', score: number, stat?: number | null, finishedAt: number };

export type GetGameProgressQueryVariables = Exact<{
  slugs: Array<Scalars['String']> | Scalars['String'];
  engine: GameEngine;
}>;


export type GetGameProgressQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, gamesProgress: Array<{ __typename?: 'GameProgress', totalPlays: number, rank: string, playsAtRank: number, nextRankAt: number, playerState?: any | null, game: { __typename?: 'Game', slug: string, areaSlug: string }, topScores: Array<{ __typename?: 'GamePlay', score: number, stat?: number | null, finishedAt: number } | null>, bestStat?: { __typename?: 'GamePlay', stat?: number | null } | null, topStats: Array<{ __typename?: 'GamePlay', score: number, stat?: number | null, finishedAt: number } | null>, lastGamePlay?: { __typename?: 'GamePlay', outputId: string, finishedAt: number, score: number, stat?: number | null } | null }> } };

export type GetGamesUnlockedQueryVariables = Exact<{
  availableOnDate?: InputMaybe<Scalars['LocalDate']>;
}>;


export type GetGamesUnlockedQuery = { __typename?: 'Query', gamesUnlocked: Array<{ __typename?: 'GameUnlock', gameSlug: string, startDate: string, endDate: string }> };

export type GetLocaleQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLocaleQuery = { __typename?: 'Query', me: { __typename?: 'User', locale?: string | null } };

export type LpiSummaryFragment = { __typename?: 'LpiSummary', overallLpi?: number | null, bestOverallLpi?: number | null, updatedAt: number, lpisByArea: Array<{ __typename?: 'AreaLpi', lpi?: number | null, areaSlug: string }>, ageCohortComparison: { __typename?: 'AgeCohortComparison', ageCohortSlug: string, overallPercentile?: number | null, bestOverallPercentile?: number | null, percentileByArea: Array<{ __typename?: 'AreaPercentile', percentile: number, areaSlug: string }> } };

export type GetLpisAndPercentilesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLpisAndPercentilesQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, lpiSummary: { __typename?: 'LpiSummary', overallLpi?: number | null, bestOverallLpi?: number | null, updatedAt: number, lpisByArea: Array<{ __typename?: 'AreaLpi', lpi?: number | null, areaSlug: string }>, ageCohortComparison: { __typename?: 'AgeCohortComparison', ageCohortSlug: string, overallPercentile?: number | null, bestOverallPercentile?: number | null, percentileByArea: Array<{ __typename?: 'AreaPercentile', percentile: number, areaSlug: string }> } } } };

export type GetStreaksQueryVariables = Exact<{
  startDate?: InputMaybe<Scalars['LocalDate']>;
}>;


export type GetStreaksQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, streakHistory: { __typename?: 'StreakHistory', streaks: Array<{ __typename?: 'Streak', startDate: string, endDate: string, length: number }>, bestStreak?: { __typename?: 'Streak', startDate: string, endDate: string, length: number } | null } } };

export type WorkoutGameFragment = { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } };

export type WorkoutSlotsFragment = { __typename?: 'WorkoutSlots', slot1: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot2: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot3: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot4: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot5: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot6: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot7: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot8: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot9: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot10: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } } };

export type GetTodaysWorkoutQueryVariables = Exact<{
  knownGameSlugs: Array<Scalars['String']> | Scalars['String'];
  currentDate?: InputMaybe<Scalars['LocalDate']>;
}>;


export type GetTodaysWorkoutQuery = { __typename?: 'Query', todaysWorkout: { __typename?: 'Workout', id: string, programComplete?: boolean | null, slots: { __typename?: 'WorkoutSlots', slot1: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot2: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot3: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot4: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot5: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot6: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot7: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot8: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot9: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot10: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } } } } };

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, firstName?: string | null, email?: string | null, birthdate: string, education?: string | null, gender?: string | null, occupation?: string | null, referralSource?: string | null, ageCohort: string, createdAt: number, migratedAt?: number | null } };

export type GetWorkoutDatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWorkoutDatesQuery = { __typename?: 'Query', workoutDates: Array<string> };

export type UpdateDemographicsMutationVariables = Exact<{
  input: DemographicInput;
}>;


export type UpdateDemographicsMutation = { __typename?: 'Mutation', updateDemographics: { __typename?: 'User', education?: string | null, gender?: string | null, occupation?: string | null, referralSource?: string | null } };

export type UpdateLocaleMutationVariables = Exact<{
  input: UpdateLocaleInput;
}>;


export type UpdateLocaleMutation = { __typename?: 'Mutation', updateLocale: { __typename?: 'User', id: number } };

export type UpdateWorkoutGameMutationVariables = Exact<{
  workoutId: Scalars['ID'];
  workoutSlot: Scalars['Int'];
  newGameSlug: Scalars['String'];
}>;


export type UpdateWorkoutGameMutation = { __typename?: 'Mutation', updateWorkoutGame: { __typename?: 'Workout', id: string, slots: { __typename?: 'WorkoutSlots', slot1: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot2: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot3: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot4: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot5: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot6: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot7: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot8: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot9: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot10: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } } } } };

export const TopScoreFragmentDoc = gql`
    fragment TopScore on GamePlay {
  score
  stat
  finishedAt
}
    `;
export const GameProgressFragmentDoc = gql`
    fragment GameProgress on GameProgress {
  game {
    slug
    areaSlug
  }
  totalPlays
  topScores {
    ...TopScore
  }
  bestStat {
    stat
  }
  topStats {
    ...TopScore
  }
  lastGamePlay {
    outputId
    finishedAt
    score
    stat
  }
  rank
  playsAtRank
  nextRankAt
  playerState
}
    ${TopScoreFragmentDoc}`;
export const LpiSummaryFragmentDoc = gql`
    fragment LpiSummary on LpiSummary {
  overallLpi
  bestOverallLpi
  lpisByArea {
    lpi
    areaSlug
  }
  ageCohortComparison {
    ageCohortSlug
    overallPercentile
    bestOverallPercentile
    percentileByArea {
      percentile
      areaSlug
    }
  }
  updatedAt
}
    `;
export const WorkoutGameFragmentDoc = gql`
    fragment WorkoutGame on WorkoutGame {
  lastGamePlay {
    finishedAt
    id
  }
  game {
    slug
  }
}
    `;
export const WorkoutSlotsFragmentDoc = gql`
    fragment WorkoutSlots on WorkoutSlots {
  slot1 {
    ...WorkoutGame
  }
  slot2 {
    ...WorkoutGame
  }
  slot3 {
    ...WorkoutGame
  }
  slot4 {
    ...WorkoutGame
  }
  slot5 {
    ...WorkoutGame
  }
  slot6 {
    ...WorkoutGame
  }
  slot7 {
    ...WorkoutGame
  }
  slot8 {
    ...WorkoutGame
  }
  slot9 {
    ...WorkoutGame
  }
  slot10 {
    ...WorkoutGame
  }
}
    ${WorkoutGameFragmentDoc}`;
export const AddCocos3GameOutputDocument = gql`
    mutation addCocos3GameOutput($input: GamePlayInput!) {
  addCocos3GameOutput(input: $input) {
    id
    finishedAt
    receivedAt
    createdAt
    gameOutput
    gameSlug
    platform
    engine
    variant
    userId
  }
}
    `;
export type AddCocos3GameOutputMutationFn = Apollo.MutationFunction<AddCocos3GameOutputMutation, AddCocos3GameOutputMutationVariables>;

/**
 * __useAddCocos3GameOutputMutation__
 *
 * To run a mutation, you first call `useAddCocos3GameOutputMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCocos3GameOutputMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCocos3GameOutputMutation, { data, loading, error }] = useAddCocos3GameOutputMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCocos3GameOutputMutation(baseOptions?: Apollo.MutationHookOptions<AddCocos3GameOutputMutation, AddCocos3GameOutputMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCocos3GameOutputMutation, AddCocos3GameOutputMutationVariables>(AddCocos3GameOutputDocument, options);
      }
export type AddCocos3GameOutputMutationHookResult = ReturnType<typeof useAddCocos3GameOutputMutation>;
export type AddCocos3GameOutputMutationResult = Apollo.MutationResult<AddCocos3GameOutputMutation>;
export type AddCocos3GameOutputMutationOptions = Apollo.BaseMutationOptions<AddCocos3GameOutputMutation, AddCocos3GameOutputMutationVariables>;
export const GetAccountStateDocument = gql`
    query GetAccountState {
  me {
    id
  }
}
    `;

/**
 * __useGetAccountStateQuery__
 *
 * To run a query within a React component, call `useGetAccountStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountStateQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountStateQuery, GetAccountStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountStateQuery, GetAccountStateQueryVariables>(GetAccountStateDocument, options);
      }
export function useGetAccountStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountStateQuery, GetAccountStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountStateQuery, GetAccountStateQueryVariables>(GetAccountStateDocument, options);
        }
export type GetAccountStateQueryHookResult = ReturnType<typeof useGetAccountStateQuery>;
export type GetAccountStateLazyQueryHookResult = ReturnType<typeof useGetAccountStateLazyQuery>;
export type GetAccountStateQueryResult = Apollo.QueryResult<GetAccountStateQuery, GetAccountStateQueryVariables>;
export const GetCurrentUserDocument = gql`
    query GetCurrentUser {
  GetCurrentUser @client {
    id
    settings {
      showSideNav
      game_sound_on
    }
  }
}
    `;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const GetDemographicInputDocument = gql`
    query GetDemographicInput {
  validEducation
  validGenders
  validOccupations
  validReferralSources
}
    `;

/**
 * __useGetDemographicInputQuery__
 *
 * To run a query within a React component, call `useGetDemographicInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDemographicInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDemographicInputQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDemographicInputQuery(baseOptions?: Apollo.QueryHookOptions<GetDemographicInputQuery, GetDemographicInputQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDemographicInputQuery, GetDemographicInputQueryVariables>(GetDemographicInputDocument, options);
      }
export function useGetDemographicInputLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDemographicInputQuery, GetDemographicInputQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDemographicInputQuery, GetDemographicInputQueryVariables>(GetDemographicInputDocument, options);
        }
export type GetDemographicInputQueryHookResult = ReturnType<typeof useGetDemographicInputQuery>;
export type GetDemographicInputLazyQueryHookResult = ReturnType<typeof useGetDemographicInputLazyQuery>;
export type GetDemographicInputQueryResult = Apollo.QueryResult<GetDemographicInputQuery, GetDemographicInputQueryVariables>;
export const GetFitTestProgressDocument = gql`
    query GetFitTestProgress {
  me {
    id
    ageCohort
    migratedAt
    fitTest {
      percentiles {
        gameSlug
        gamePlay {
          outputId
        }
        percentile
      }
    }
  }
}
    `;

/**
 * __useGetFitTestProgressQuery__
 *
 * To run a query within a React component, call `useGetFitTestProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFitTestProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFitTestProgressQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFitTestProgressQuery(baseOptions?: Apollo.QueryHookOptions<GetFitTestProgressQuery, GetFitTestProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFitTestProgressQuery, GetFitTestProgressQueryVariables>(GetFitTestProgressDocument, options);
      }
export function useGetFitTestProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFitTestProgressQuery, GetFitTestProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFitTestProgressQuery, GetFitTestProgressQueryVariables>(GetFitTestProgressDocument, options);
        }
export type GetFitTestProgressQueryHookResult = ReturnType<typeof useGetFitTestProgressQuery>;
export type GetFitTestProgressLazyQueryHookResult = ReturnType<typeof useGetFitTestProgressLazyQuery>;
export type GetFitTestProgressQueryResult = Apollo.QueryResult<GetFitTestProgressQuery, GetFitTestProgressQueryVariables>;
export const GetGameProgressDocument = gql`
    query GetGameProgress($slugs: [String!]!, $engine: GameEngine!) {
  me {
    id
    gamesProgress(slugs: $slugs, engine: $engine) {
      ...GameProgress
    }
  }
}
    ${GameProgressFragmentDoc}`;

/**
 * __useGetGameProgressQuery__
 *
 * To run a query within a React component, call `useGetGameProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameProgressQuery({
 *   variables: {
 *      slugs: // value for 'slugs'
 *      engine: // value for 'engine'
 *   },
 * });
 */
export function useGetGameProgressQuery(baseOptions: Apollo.QueryHookOptions<GetGameProgressQuery, GetGameProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGameProgressQuery, GetGameProgressQueryVariables>(GetGameProgressDocument, options);
      }
export function useGetGameProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGameProgressQuery, GetGameProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGameProgressQuery, GetGameProgressQueryVariables>(GetGameProgressDocument, options);
        }
export type GetGameProgressQueryHookResult = ReturnType<typeof useGetGameProgressQuery>;
export type GetGameProgressLazyQueryHookResult = ReturnType<typeof useGetGameProgressLazyQuery>;
export type GetGameProgressQueryResult = Apollo.QueryResult<GetGameProgressQuery, GetGameProgressQueryVariables>;
export const GetGamesUnlockedDocument = gql`
    query GetGamesUnlocked($availableOnDate: LocalDate) {
  gamesUnlocked(availableOnDate: $availableOnDate) {
    gameSlug
    startDate
    endDate
  }
}
    `;

/**
 * __useGetGamesUnlockedQuery__
 *
 * To run a query within a React component, call `useGetGamesUnlockedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGamesUnlockedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGamesUnlockedQuery({
 *   variables: {
 *      availableOnDate: // value for 'availableOnDate'
 *   },
 * });
 */
export function useGetGamesUnlockedQuery(baseOptions?: Apollo.QueryHookOptions<GetGamesUnlockedQuery, GetGamesUnlockedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGamesUnlockedQuery, GetGamesUnlockedQueryVariables>(GetGamesUnlockedDocument, options);
      }
export function useGetGamesUnlockedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGamesUnlockedQuery, GetGamesUnlockedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGamesUnlockedQuery, GetGamesUnlockedQueryVariables>(GetGamesUnlockedDocument, options);
        }
export type GetGamesUnlockedQueryHookResult = ReturnType<typeof useGetGamesUnlockedQuery>;
export type GetGamesUnlockedLazyQueryHookResult = ReturnType<typeof useGetGamesUnlockedLazyQuery>;
export type GetGamesUnlockedQueryResult = Apollo.QueryResult<GetGamesUnlockedQuery, GetGamesUnlockedQueryVariables>;
export const GetLocaleDocument = gql`
    query GetLocale {
  me {
    locale
  }
}
    `;

/**
 * __useGetLocaleQuery__
 *
 * To run a query within a React component, call `useGetLocaleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocaleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocaleQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocaleQuery(baseOptions?: Apollo.QueryHookOptions<GetLocaleQuery, GetLocaleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocaleQuery, GetLocaleQueryVariables>(GetLocaleDocument, options);
      }
export function useGetLocaleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocaleQuery, GetLocaleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocaleQuery, GetLocaleQueryVariables>(GetLocaleDocument, options);
        }
export type GetLocaleQueryHookResult = ReturnType<typeof useGetLocaleQuery>;
export type GetLocaleLazyQueryHookResult = ReturnType<typeof useGetLocaleLazyQuery>;
export type GetLocaleQueryResult = Apollo.QueryResult<GetLocaleQuery, GetLocaleQueryVariables>;
export const GetLpisAndPercentilesDocument = gql`
    query GetLpisAndPercentiles {
  me {
    id
    lpiSummary {
      ...LpiSummary
    }
  }
}
    ${LpiSummaryFragmentDoc}`;

/**
 * __useGetLpisAndPercentilesQuery__
 *
 * To run a query within a React component, call `useGetLpisAndPercentilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLpisAndPercentilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLpisAndPercentilesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLpisAndPercentilesQuery(baseOptions?: Apollo.QueryHookOptions<GetLpisAndPercentilesQuery, GetLpisAndPercentilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLpisAndPercentilesQuery, GetLpisAndPercentilesQueryVariables>(GetLpisAndPercentilesDocument, options);
      }
export function useGetLpisAndPercentilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLpisAndPercentilesQuery, GetLpisAndPercentilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLpisAndPercentilesQuery, GetLpisAndPercentilesQueryVariables>(GetLpisAndPercentilesDocument, options);
        }
export type GetLpisAndPercentilesQueryHookResult = ReturnType<typeof useGetLpisAndPercentilesQuery>;
export type GetLpisAndPercentilesLazyQueryHookResult = ReturnType<typeof useGetLpisAndPercentilesLazyQuery>;
export type GetLpisAndPercentilesQueryResult = Apollo.QueryResult<GetLpisAndPercentilesQuery, GetLpisAndPercentilesQueryVariables>;
export const GetStreaksDocument = gql`
    query GetStreaks($startDate: LocalDate) {
  me {
    id
    streakHistory {
      streaks(startDate: $startDate) {
        startDate
        endDate
        length
      }
      bestStreak {
        startDate
        endDate
        length
      }
    }
  }
}
    `;

/**
 * __useGetStreaksQuery__
 *
 * To run a query within a React component, call `useGetStreaksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStreaksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStreaksQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useGetStreaksQuery(baseOptions?: Apollo.QueryHookOptions<GetStreaksQuery, GetStreaksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStreaksQuery, GetStreaksQueryVariables>(GetStreaksDocument, options);
      }
export function useGetStreaksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStreaksQuery, GetStreaksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStreaksQuery, GetStreaksQueryVariables>(GetStreaksDocument, options);
        }
export type GetStreaksQueryHookResult = ReturnType<typeof useGetStreaksQuery>;
export type GetStreaksLazyQueryHookResult = ReturnType<typeof useGetStreaksLazyQuery>;
export type GetStreaksQueryResult = Apollo.QueryResult<GetStreaksQuery, GetStreaksQueryVariables>;
export const GetTodaysWorkoutDocument = gql`
    query GetTodaysWorkout($knownGameSlugs: [String!]!, $currentDate: LocalDate) {
  todaysWorkout(knownGameSlugs: $knownGameSlugs, currentDate: $currentDate) {
    id
    slots {
      ...WorkoutSlots
    }
    programComplete
  }
}
    ${WorkoutSlotsFragmentDoc}`;

/**
 * __useGetTodaysWorkoutQuery__
 *
 * To run a query within a React component, call `useGetTodaysWorkoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTodaysWorkoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTodaysWorkoutQuery({
 *   variables: {
 *      knownGameSlugs: // value for 'knownGameSlugs'
 *      currentDate: // value for 'currentDate'
 *   },
 * });
 */
export function useGetTodaysWorkoutQuery(baseOptions: Apollo.QueryHookOptions<GetTodaysWorkoutQuery, GetTodaysWorkoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTodaysWorkoutQuery, GetTodaysWorkoutQueryVariables>(GetTodaysWorkoutDocument, options);
      }
export function useGetTodaysWorkoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTodaysWorkoutQuery, GetTodaysWorkoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTodaysWorkoutQuery, GetTodaysWorkoutQueryVariables>(GetTodaysWorkoutDocument, options);
        }
export type GetTodaysWorkoutQueryHookResult = ReturnType<typeof useGetTodaysWorkoutQuery>;
export type GetTodaysWorkoutLazyQueryHookResult = ReturnType<typeof useGetTodaysWorkoutLazyQuery>;
export type GetTodaysWorkoutQueryResult = Apollo.QueryResult<GetTodaysWorkoutQuery, GetTodaysWorkoutQueryVariables>;
export const GetUserDocument = gql`
    query GetUser {
  me {
    id
    firstName
    email
    birthdate
    education
    gender
    occupation
    referralSource
    ageCohort
    createdAt
    migratedAt
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetWorkoutDatesDocument = gql`
    query GetWorkoutDates {
  workoutDates
}
    `;

/**
 * __useGetWorkoutDatesQuery__
 *
 * To run a query within a React component, call `useGetWorkoutDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkoutDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkoutDatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWorkoutDatesQuery(baseOptions?: Apollo.QueryHookOptions<GetWorkoutDatesQuery, GetWorkoutDatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkoutDatesQuery, GetWorkoutDatesQueryVariables>(GetWorkoutDatesDocument, options);
      }
export function useGetWorkoutDatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkoutDatesQuery, GetWorkoutDatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkoutDatesQuery, GetWorkoutDatesQueryVariables>(GetWorkoutDatesDocument, options);
        }
export type GetWorkoutDatesQueryHookResult = ReturnType<typeof useGetWorkoutDatesQuery>;
export type GetWorkoutDatesLazyQueryHookResult = ReturnType<typeof useGetWorkoutDatesLazyQuery>;
export type GetWorkoutDatesQueryResult = Apollo.QueryResult<GetWorkoutDatesQuery, GetWorkoutDatesQueryVariables>;
export const UpdateDemographicsDocument = gql`
    mutation UpdateDemographics($input: DemographicInput!) {
  updateDemographics(input: $input) {
    education
    gender
    occupation
    referralSource
  }
}
    `;
export type UpdateDemographicsMutationFn = Apollo.MutationFunction<UpdateDemographicsMutation, UpdateDemographicsMutationVariables>;

/**
 * __useUpdateDemographicsMutation__
 *
 * To run a mutation, you first call `useUpdateDemographicsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDemographicsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDemographicsMutation, { data, loading, error }] = useUpdateDemographicsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDemographicsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDemographicsMutation, UpdateDemographicsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDemographicsMutation, UpdateDemographicsMutationVariables>(UpdateDemographicsDocument, options);
      }
export type UpdateDemographicsMutationHookResult = ReturnType<typeof useUpdateDemographicsMutation>;
export type UpdateDemographicsMutationResult = Apollo.MutationResult<UpdateDemographicsMutation>;
export type UpdateDemographicsMutationOptions = Apollo.BaseMutationOptions<UpdateDemographicsMutation, UpdateDemographicsMutationVariables>;
export const UpdateLocaleDocument = gql`
    mutation UpdateLocale($input: UpdateLocaleInput!) {
  updateLocale(input: $input) {
    id
  }
}
    `;
export type UpdateLocaleMutationFn = Apollo.MutationFunction<UpdateLocaleMutation, UpdateLocaleMutationVariables>;

/**
 * __useUpdateLocaleMutation__
 *
 * To run a mutation, you first call `useUpdateLocaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocaleMutation, { data, loading, error }] = useUpdateLocaleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLocaleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLocaleMutation, UpdateLocaleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLocaleMutation, UpdateLocaleMutationVariables>(UpdateLocaleDocument, options);
      }
export type UpdateLocaleMutationHookResult = ReturnType<typeof useUpdateLocaleMutation>;
export type UpdateLocaleMutationResult = Apollo.MutationResult<UpdateLocaleMutation>;
export type UpdateLocaleMutationOptions = Apollo.BaseMutationOptions<UpdateLocaleMutation, UpdateLocaleMutationVariables>;
export const UpdateWorkoutGameDocument = gql`
    mutation updateWorkoutGame($workoutId: ID!, $workoutSlot: Int!, $newGameSlug: String!) {
  updateWorkoutGame(
    workoutId: $workoutId
    workoutSlot: $workoutSlot
    newGameSlug: $newGameSlug
  ) {
    id
    slots {
      ...WorkoutSlots
    }
  }
}
    ${WorkoutSlotsFragmentDoc}`;
export type UpdateWorkoutGameMutationFn = Apollo.MutationFunction<UpdateWorkoutGameMutation, UpdateWorkoutGameMutationVariables>;

/**
 * __useUpdateWorkoutGameMutation__
 *
 * To run a mutation, you first call `useUpdateWorkoutGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkoutGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkoutGameMutation, { data, loading, error }] = useUpdateWorkoutGameMutation({
 *   variables: {
 *      workoutId: // value for 'workoutId'
 *      workoutSlot: // value for 'workoutSlot'
 *      newGameSlug: // value for 'newGameSlug'
 *   },
 * });
 */
export function useUpdateWorkoutGameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkoutGameMutation, UpdateWorkoutGameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorkoutGameMutation, UpdateWorkoutGameMutationVariables>(UpdateWorkoutGameDocument, options);
      }
export type UpdateWorkoutGameMutationHookResult = ReturnType<typeof useUpdateWorkoutGameMutation>;
export type UpdateWorkoutGameMutationResult = Apollo.MutationResult<UpdateWorkoutGameMutation>;
export type UpdateWorkoutGameMutationOptions = Apollo.BaseMutationOptions<UpdateWorkoutGameMutation, UpdateWorkoutGameMutationVariables>;
export const namedOperations = {
  Query: {
    GetAccountState: 'GetAccountState',
    GetCurrentUser: 'GetCurrentUser',
    GetDemographicInput: 'GetDemographicInput',
    GetFitTestProgress: 'GetFitTestProgress',
    GetGameProgress: 'GetGameProgress',
    GetGamesUnlocked: 'GetGamesUnlocked',
    GetLocale: 'GetLocale',
    GetLpisAndPercentiles: 'GetLpisAndPercentiles',
    GetStreaks: 'GetStreaks',
    GetTodaysWorkout: 'GetTodaysWorkout',
    GetUser: 'GetUser',
    GetWorkoutDates: 'GetWorkoutDates'
  },
  Mutation: {
    addCocos3GameOutput: 'addCocos3GameOutput',
    UpdateDemographics: 'UpdateDemographics',
    UpdateLocale: 'UpdateLocale',
    updateWorkoutGame: 'updateWorkoutGame'
  },
  Fragment: {
    GameProgress: 'GameProgress',
    TopScore: 'TopScore',
    LpiSummary: 'LpiSummary',
    WorkoutGame: 'WorkoutGame',
    WorkoutSlots: 'WorkoutSlots'
  }
}